<template>
  <b-container fluid="md" class="inner-container min-vh-100">
    <div class="row h-100 justify-content-center align-items-center">
      <img src="@/assets/confirm_order.svg"  class="centralised col-12">
      <div class="col-12">
        <h3>Your order has been placed</h3>
        <p>All of your orders and receipts are stored in your account</p>
      </div>
      <b-button class="view-order-button offset-3 col-6 col-sm-4 col-md-2" @click="viewOrder">View Order</b-button>
      <div class="gta col-12">
        <h4>Get the app to see your orders</h4>
      </div>
      <div class="col-5 col-sm-4
       col-md-2 justify-content-end  pr-0 pl-0">
        <b-button class="apple-btn" @click="redirectToApple"></b-button>
      </div>
      <div class="col-5 col-sm-4 col-md-2 justify-content-start pr-0 pl-0">
        <b-button class="android-btn" @click="redirectToAndroid"></b-button>
      </div>

    </div>
  </b-container>
</template>

<script>
export default {
  name: 'Success',

  mounted() {

  },
  methods:{
    viewOrder(){
      this.$router.push('/v/' + this.$route.query.venue + '/order/' + this.$route.query.order);
    },
    redirectToApple()
    {
      window.location.href = 'https://apps.apple.com/gb/app/id1521447907';
    },
    redirectToAndroid()
    {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.imageplus.localglobal';
    }

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

 .centralised {
   max-height: 25vh;
   margin-top: 10vh;
 }
 h3{
   margin-top: 30px;
   font-weight: 600;
   font-size: 20px;
   color: #070314;
 }
 p{
   font-size: 15px;
   font-weight: 400;
   color: #070314;
 }
 h4{
   margin-top: 20px;
   font-size: 14px;
   font-weight: 700;
   color: #070314;
 }
 .apple-btn{
   width: 120px;
   height: 40px;
   border: none;
   background-repeat: no-repeat;
   border-radius: 8px;
   background-image: url('~@/assets/download_app_store.svg');
 }
 .android-btn{
   width: 135px;
   height: 40px;
   border: none;
   background-repeat: no-repeat;
   border-radius: 8px;
   background-image: url('~@/assets/download_play_store.svg');
 }
 .view-order-button{
   width: 100%;
   border-radius: 8px;
   background: linear-gradient(270deg,#71C1FF,#6F47FF);
   border: none;
   margin-top: 10px;
   margin-left: 4px;
   margin-right: 4px;
 }
</style>
